import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import React from 'react';
import { Field, reduxForm } from 'redux-form';

import core from 'core';

function Filter({
	newUrl,
	filter,
	clearFilter,
	handleSubmit,
	status,
	setStatus,
	currentUser,
	updateShopType,
	downloadShops,
	isDownloadingShops,
}) {
	const _handleClick = (status) => {
		setStatus(status);
		updateShopType(status);
	};

	return (
		<div className='row mb-2'>
			<div className='col-lg-12'>
				<form
					onSubmit={handleSubmit}
					className='form-inline shop-filter-form shop-list-filter-row '
				>
					<div className='form-group'>
						<div className='btn-group status-chooser'>
							<button
								type='button'
								onClick={() => _handleClick('active')}
								className={classNames('btn', {
									'btn-light': status !== 'active',
									'btn-primary': status === 'active',
								})}
							>
								Идэвхтэй
							</button>
							<button
								type='button'
								onClick={() => _handleClick('trial')}
								className={classNames('btn', {
									'btn-light': status !== 'trial',
									'btn-primary': status === 'trial',
								})}
							>
								Шинэ
							</button>
							<button
								type='button'
								onClick={() => _handleClick('inactive')}
								className={classNames('btn', {
									'btn-light': status !== 'inactive',
									'btn-primary': status === 'inactive',
								})}
							>
								Идэвхгүй
							</button>
							<button
								type='button'
								onClick={() => _handleClick('all')}
								className={classNames('btn', {
									'btn-light': status !== 'all',
									'btn-primary': status === 'all',
								})}
							>
								Бүгд
							</button>
						</div>
					</div>
					<div className='form-group'>
						<Field
							component='input'
							type='text'
							className='form-control'
							placeholder='Нэр...'
							name='name'
						/>
					</div>
					<div className='form-group'>
						<Field
							component='input'
							type='text'
							className='form-control'
							placeholder='Утас...'
							name='phone'
						/>
					</div>
					<div className='form-group'>
						<Field
							name='start'
							placeholder='Огноо /from/'
							component={core.components.DateTimePicker}
						/>
					</div>
					<div className='form-group'>
						<Field name='end' placeholder='/to/' component={core.components.DateTimePicker} />
					</div>
					<div style={{ width: 300 }} className='form-group'>
						<Field
							multi={true}
							name='channels'
							placeholder='Борлуулалтын суваг..'
							options={[
								{ value: 'web', label: 'Zochil web' },
								{ value: 'hipay', label: 'hipay' },
								{ value: 'monpay', label: 'monpay' },
								{ value: 'socialpay', label: 'socialpay' },
								{ value: 'zamch', label: 'Zamch' },
								{ value: 'kool', label: 'Kool' },
							]}
							component={core.components.SelectField}
						/>
					</div>
					<div style={{ width: 300 }} className='form-group'>
						<Field
							multi={true}
							name='category_codes'
							placeholder='Мастер ангилал..'
							options={[
								{ value: 'A5', label: 'Гоо сайхан' },
								{ value: 'A3', label: 'Спорт' },
								{ value: 'A4', label: 'Хүнс' },
								{ value: 'A9', label: 'Бичиг хэрэг' },
								{ value: 'A2', label: 'Эрэгтэй' },
								{ value: 'A0', label: 'Гэр ахуй' },
								{ value: 'A1', label: 'Эмэгтэй' },
								{ value: 'A7', label: 'Үнэт эдлэл' },
								{ value: 'A6', label: 'Хүүхдийн' },
								{ value: 'A8', label: 'Электрон бараа' },
								{ value: 'A11', label: 'Авто' },
								{ value: 'A12', label: 'Хувцас' },
								{ value: 'A13', label: 'Амьтан' },
								{ value: 'A15', label: 'Хөгжим' },
								{ value: 'A14', label: 'Эрүүл мэнд' },
								{ value: 'A16', label: 'Үйлчилгээ' },
								{ value: 'A17', label: 'Сургалт' },
								{ value: 'A18', label: 'Цүнх' },
								{ value: 'A19', label: 'Гутал' },
								{ value: 'A20', label: 'Бусад' },
							]}
							component={core.components.SelectField}
						/>
					</div>
					<div className='form-group'>
						<Field
							name='expire_start'
							placeholder='Гэрээ дуусах /from/'
							component={core.components.DateTimePicker}
						/>
					</div>
					<div className='form-group'>
						<Field
							name='expire_end'
							placeholder='/to/'
							component={core.components.DateTimePicker}
						/>
					</div>
					<div className='form-group'>
						{/* <Field
              name="email"
              placeholder="И-мэйл"
              className="form-control"
              component="input"
            /> */}
						<Field
							name='email'
							type='email'
							component='input'
							className='form-control'
							placeholder='И-мэйл хаяг'
						/>
					</div>
					<div className='form-group'>
						<button className='btn btn-primary text-bold'>ХАЙХ</button>{' '}
						{!!(filter && !isEqual(filter, {})) && (
							<button
								onClick={() => clearFilter()}
								className='btn btn-danger ml-2 text-bold'
							>
								<i className='uil-times-square'></i>
							</button>
						)}
						{['ganbold', 'tumennast', 'sanjaa'].indexOf(currentUser.username) > -1 && (
							<button
								disabled={isDownloadingShops}
								onClick={() => downloadShops({ ...filter, status })}
								className='btn btn-success ml-2 text-bold'
							>
								<i className='mdi mdi-file-excel' />
							</button>
						)}
					</div>
				</form>
			</div>
		</div>
	);
}

export default reduxForm({
	form: 'shopsFilterForm',
	enableReinitialize: true,
})(Filter);
