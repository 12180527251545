import React from "react";
import moment from "moment";

import { reduxForm, Field } from "redux-form";

import core from "core";

function OptionsTable({
  handleSubmit,
  removePM,
  isAddingPM,
  paymentMethods = [],
}) {
  return (
    <div id="shop-pm" className="tab-pane fade" role="tabpanel">
      <div className="row mb-2">
        <div className="col-lg-12">
          <form
            onSubmit={handleSubmit}
            className="form-inline shop-filter-form"
          >
            <div style={{ width: 200 }} className="form-group mr-2">
              <Field
                required
                name="provider"
                disabled={isAddingPM}
                placeholder="Төлбөрийн хэрэгсэл..."
                options={[
                  { label: "Checkout QR", value: "minu_qr" },
                  { label: "Checkout Card", value: "minu_card" },
                  { label: "Карт /Голомт/", value: "golomt_card" },
                  { label: "QPay", value: "qpay" },
                  { label: "HiPay", value: "hipay" },
                  { label: "StorePay", value: "storepay" },
                  { label: "LendPay", value: "lendpay" },
                  { label: "Pocket", value: "pocket" },
                  { label: "SocialPay", value: "socialpay" },
                  { label: "Tokipay", value: "toki" },
                ]}
                component={core.components.SelectField}
              />
            </div>
            <div style={{ width: 200 }} className="form-group mr-2">
              <Field
                name="bank"
                disabled={isAddingPM}
                placeholder="Банк..."
                options={[
                  { value: "khan", label: "Хаан банк" },
                  { value: "golomt", label: "Голомт банк" },
                  { value: "state", label: "Төрийн банк" },
                  { value: "tdb", label: "ХХБ" },
                  { value: "xac", label: "Хас Банк" },
                  { value: "ubcity", label: "Улаанбаатар хотын банк" },
                  { value: "capitron", label: "Капитрон банк" },
                  { value: "arig", label: "Ариг банк" },
                  { value: "bogd", label: "Богд банк" },
                  { value: "mbank", label: "М банк" },
                ]}
                component={core.components.SelectField}
              />
            </div>
            <div style={{ width: 200 }} className="form-group mr-2">
              <Field
                style={{ width: 200 }}
                disabled={isAddingPM}
                component="input"
                type="text"
                className="form-control"
                placeholder="Данс"
                name="account_number"
              />
            </div>
            <div style={{ width: 200 }} className="form-group mr-2">
              <Field
                style={{ width: 200 }}
                disabled={isAddingPM}
                component="input"
                type="text"
                className="form-control"
                placeholder="Дансны нэр"
                name="account_holder"
              />
            </div>
            <div className="form-group">
              <button
                disabled={isAddingPM}
                className="btn btn-primary text-bold"
              >
                НЭМЭХ
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="table-responsive">
        <table className="shops-table table table-centered mb-0">
          <thead className="thead-light">
            <tr>
              <th style={{ width: 20 }}>#</th>
              <th style={{ width: 150 }}>Банк / ТХ</th>
              <th style={{ width: 150 }}>Данс</th>
              <th>Дасны нэр</th>
              <th style={{ width: 150 }}>Огноо</th>
              <th className="text-center" style={{ width: 50 }} />
            </tr>
          </thead>
          <tbody>
            {(paymentMethods || []).map((row, index) => (
              <tr key={`tr_${row.id}`}>
                <td>{index + 1}</td>
                <td className="name">{row.provider.toUpperCase()}</td>
                <td>{row.account_number || "-"}</td>
                <td>{row.account_holder || "-"}</td>
                <td>{moment(row.created_at).format("YYYY-MM-DD HH:mm")}</td>
                <td className="text-center" style={{ width: 50 }}>
                  {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                  <a
                    href="#"
                    className={"action-icon text-danger"}
                    onClick={(event) => {
                      event.preventDefault();
                      if (window.confirm("Та итгэлтэй байна уу?")) {
                        removePM(row.provider);
                      }
                    }}
                  >
                    <i className="mdi mdi-trash-can-outline" />
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default reduxForm({
  form: "shopPMTable",
  enableReinitialize: true,
})(OptionsTable);
