import omit from 'lodash/omit';
import * as types from './actionTypes';
import * as constants from './constants';
export const initialState = {
	errors: {},
};

const notesReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.FETCH_NOTES_BEGIN:
			return {
				...state,
				isFetchingNotes: true,
			};

		case types.FETCH_NOTES_COMPLETE:
			return {
				...state,
				isFetchingNotes: false,
				notes: action.payload.notes,
			};

		case types.FETCH_NOTES_ERROR:
			return {
				...state,
				isFetchingNotes: false,
				errors: {
					...state.errors,
					[constants.FETCH_NOTES]: action.payload.error.message,
				},
			};
		case types.FETCH_NOTE_DETAIL_BEGIN:
			return {
				...state,
				isFetchingNoteDetail: true,
			};

		case types.FETCH_NOTE_DETAIL_COMPLETE:
			return {
				...state,
				isFetchingNoteDetail: false,
				noteDetail: action.payload.noteDetail,
			};

		case types.FETCH_NOTE_DETAIL_ERROR:
			return {
				...state,
				isFetchingNoteDetail: false,
				errors: {
					...state.errors,
					[constants.FETCH_NOTE_DETAIL]: action.payload.error.message,
				},
			};

		case types.ADD_NOTE_BEGIN:
			return {
				...state,
				isAddingNotes: true,
			};

		case types.ADD_NOTE_COMPLETE:
			return {
				...state,
				isAddingNotes: false,
			};

		case types.ADD_NOTE_ERROR:
			return {
				...state,
				isAddingNotes: false,
				errors: {
					...state.errors,
					[constants.ADD_NOTE]: action.payload.error.message,
				},
			};

		case '__PLACEHOLDER__':
			// ignore this.
			return state;

		case types.RESET_ERROR:
			return {
				...state,
				errors: omit(state.errors, action.payload.name),
			};

		case types.SET_FILTER:
			return {
				...state,
				filter: action.payload.filter,
			};

		default:
			return state;
	}
};

export default notesReducer;
