import core from 'core';
import React from 'react';

function Table({ rows = [], limit, currentPage }) {
	function formatDate(date) {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;

		return [year, month, day].join('-');
	}

	return (
		<div className='table-responsive'>
			<table className=' table table-centered mb-0'>
				<thead className='thead-light'>
					<tr>
						<th style={{ width: 20 }}>#</th>
						<th style={{ maxWidth: '200px' }}>Гарчиг</th>
						<th style={{ maxWidth: '600px' }}></th>
						<th>Бүртгэсэн ажилтан</th>
						<th>Огноо</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{rows.map((row, index) => (
						<>
							<tr key={`tr_${row.id}`}>
								<td>{core.helpers.getTableIndex(currentPage, limit, index)}</td>
								<td className='text-truncate' style={{ maxWidth: '200px' }}>
									{row.title}
								</td>
								<td className='text-truncate' style={{ maxWidth: '600px' }}>
									{row.description}
								</td>

								<td>{row.username}</td>
								<td>{formatDate(row.created_at)}</td>
								<td>
									<button
										type='button'
										className='btn btn-link'
										data-toggle='modal'
										data-target='#noteModal'
									>
										<i className='mdi mdi-arrow-right font-md fs-2' />
									</button>
								</td>
							</tr>
							<div
								className='modal fade'
								id='noteModal'
								tabindex='-1'
								role='dialog'
								aria-labelledby='noteModal'
								aria-hidden='true'
							>
								<div className='modal-dialog' role='document'>
									<div className='modal-content'>
										<div className='modal-header'>
											<button
												type='button'
												className='close'
												data-dismiss='modal'
												aria-label='Close'
											>
												<span aria-hidden='true'>
													<i className='uil uil-multiply' />
												</span>
											</button>
										</div>
										<div className='modal-body'>
											<div className='d-flex align-item-center justify-content-between mb-2'>
												<p class='card-text'>{row.username}</p>
												<p class='card-text'>{formatDate(row.created_at)}</p>
											</div>
											<h5 class='card-title'>{row.title}</h5>
											<p class='card-text'>{row.description}</p>
										</div>
									</div>
								</div>
							</div>
						</>
					))}
				</tbody>
			</table>
		</div>
	);
}

export default Table;
