import activities from './activities';
import ad_campaigns from './ad-campaigns';
import ad_sellers from './ad-sellers';
import admins from './admins';
import bills from './bills';
import campaigns from './campaigns';
import channels from './channels';
import contracts from './contracts';
import core from './core';
import customers from './customers';
import dashboard from './dashboard';
import domains from './domains';
import issues from './issues';
import modules from './modules';
import notes from './notes';
import notifications from './notifications';
import orders from './orders';
import payments from './payments';
import products from './products';
import settlements from './settlements';
import shops from './shops';
import transactions from './transactions';
import users from './users';
import withdrawals from './withdrawals';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	core,
	shops,
	users,
	bills,
	admins,
	orders,
	issues,
	domains,
	products,
	channels,
	dashboard,
	customers,
	activities,
	settlements,
	notifications,
	transactions,
	withdrawals,
	payments,
	contracts,
	modules,
	campaigns,
	ad_sellers,
	ad_campaigns,
	notes,
};
