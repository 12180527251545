import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { reset } from 'redux-form';

import core from 'core';
import * as actions from '../../../actions';
import * as constants from '../../../constants';
import * as selectors from '../../../selectors';

const loading = selectors.makeLoadingSelector();

export const mapStateToProps = (state, { location: { search } }) => ({
	isFetching: loading(state[constants.NAME], constants.FETCH_NOTES),
	fetchResult: selectors.getNotes(state[constants.NAME]) || {
		notes: [],
	},
	currentUser: core.selectors.getCurrentUser(state[core.constants.NAME]) || {},
	filter: selectors.getFilter(state[constants.NAME]),
	...core.selectors.getPaginationProps(
		selectors.getNotes(state[constants.NAME]) || { totalCount: 0 },
		search,
	),
	isFetchingNoteDetail: loading(state[constants.NAME], constants.FETCH_NOTE_DETAIL),
	fetchNoteDetailResult: selectors.getNoteDetail(state[constants.NAME]) || {},
});
export const mapDispatchToProps = (dispatch, { match: { params } }) => ({
	fetchNotes(page, filter) {
		dispatch(actions.fetchNotes({ merchant_id: params.id, options: { page }, filter }));
	},
	setFilter(filter) {
		dispatch(actions.setFilter({ filter }));
	},
	clearFilter() {
		dispatch(reset('notesFilterForm'));
		dispatch(actions.setFilter({ filter: null }));
	},
	updateQueryString(page) {
		dispatch(push(`/notes/${params.id}?page=${page}`));
	},
	fetchNoteDetail(merchant_id) {
		dispatch(actions.fetchNoteDetail({ merchant_id }));
	},
});

export default (Component) => connect(mapStateToProps, mapDispatchToProps)(Component);
