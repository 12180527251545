import activities from 'app/activities';
import ad_campaigns from 'app/ad/ad-campaigns';
import ad_sellers from 'app/ad/sellers';
import withdrawals from 'app/ad/withdrawals';
import admins from 'app/admins';
import bills from 'app/bills';
import campaigns from 'app/campaigns';
import channels from 'app/channels';
import contracts from 'app/contracts';
import customers from 'app/customers';
import dashboard from 'app/dashboard';
import issues from 'app/issues';
import messages from 'app/messages';
import modules from 'app/modules';
import notes from 'app/notes';
import notifications from 'app/notifications';
import orders from 'app/orders';
import payments from 'app/payments';
import products from 'app/products';
import settlements from 'app/settlements';
import shops from 'app/shops';
import transactions from 'app/transactions';
import users from 'app/users';
import core from 'core';

// eslint-disable-next-line import/no-anonymous-default-export
export default [
	...core.routes,
	...bills.routes,
	...shops.routes,
	...users.routes,
	...orders.routes,
	...admins.routes,
	...issues.routes,
	...messages.routes,
	...channels.routes,
	...products.routes,
	...dashboard.routes,
	...customers.routes,
	...activities.routes,
	...settlements.routes,
	...payments.routes,
	...contracts.routes,
	...modules.routes,
	...campaigns.routes,
	...notifications.routes,
	...transactions.routes,
	...withdrawals.routes,
	...ad_campaigns.routes,
	...ad_sellers.routes,
	...notes.routes,
];
