import { push } from 'connected-react-router';
import { connect } from 'react-redux';

import core from 'core';
import * as actions from '../../../actions';
import * as constants from '../../../constants';
import * as selectors from '../../../selectors';

const error = selectors.makeErrorSelector();
const loading = selectors.makeLoadingSelector();

export const mapStateToProps = (state, { match }) => ({
	currentUser: core.selectors.getCurrentUser(state[core.constants.NAME]) || {},
	linkDomainError: error(state[constants.NAME], constants.LINK_DOMAIN),
	isLinkingDomain: loading(state[constants.NAME], constants.LINK_DOMAIN),
	isCheckingDomain: loading(state[constants.NAME], constants.CHECK_DOMAIN),
	isRemovingDomain: loading(state[constants.NAME], constants.REMOVE_DOMAIN),

	linkUserError: error(state[constants.NAME], constants.LINK_USER),
	unlinkUserError: error(state[constants.NAME], constants.UNLINK_USER),
	isLinkingUser: loading(state[constants.NAME], constants.LINK_USER),
	isUnlinkingUser: loading(state[constants.NAME], constants.UNLINK_USER),

	updateBalanceError: error(state[constants.NAME], constants.UPDATE_MERCHANT_MODULE),
	isUpdatingBalance: loading(state[constants.NAME], constants.UPDATE_MERCHANT_MODULE),

	adjustPlanModuleError: error(state[constants.NAME], constants.ADJUST_PLAN_MODULE),
	isAdjustingPlanModule: loading(state[constants.NAME], constants.ADJUST_PLAN_MODULE),

	isAddingOption: loading(state[constants.NAME], constants.ADD_OPTION),
	isAddingPM: loading(state[constants.NAME], constants.ADD_PAYMENT_METHOD),
	isRemovingOption: loading(state[constants.NAME], constants.REMOVE_OPTION),
	isRemovingPM: loading(state[constants.NAME], constants.REMOVE_PAYMENT_METHOD),
	isFetching: loading(state[constants.NAME], constants.FETCH_SHOP_DETAIL),
	shop: selectors.getShopDetail(state[constants.NAME]) || {},
	shopHits: selectors.getShopHits(state[constants.NAME]) || {
		days: [],
		months: [],
	},
	shopOrderStat: selectors.getShopOrderStat(state[constants.NAME]) || {
		days: [],
		months: [],
	},
	isFetchingNotes: loading(state[constants.NAME], constants.FETCH_NOTES_LATEST),
	fetchNotesResult: selectors.getNotesLatest(state[constants.NAME]) || {
		notes: [],
	},
	isFetchingNoteDetail: loading(state[constants.NAME], constants.FETCH_NOTE_DETAIL),
	fetchNoteDetailResult: selectors.getNoteDetail(state[constants.NAME]) || {},
});
export const mapDispatchToProps = (dispatch, { match: { params } }) => ({
	fetchShop() {
		if (params.id) {
			dispatch(actions.fetchShopDetail({ id: params.id }));
			dispatch(actions.fetchShopHits({ shop_id: params.id }));
			dispatch(actions.fetchShopOrderStat({ shop_id: params.id }));
		}
	},
	linkDomain(id, domain, theme) {
		dispatch(actions.linkDomain({ id, domain, theme }));
	},
	removeDomain(id) {
		dispatch(actions.removeDomain({ id }));
	},
	checkDomain(id, domain) {
		dispatch(actions.checkDomain({ id, domain }));
	},
	updateBalance(shop_id, balance) {
		dispatch(actions.updateBalance({ shop_id, balance }));
	},
	adjustPlanModule(shop_id, plan_uid, quantity) {
		dispatch(actions.adjustPlanModule({ shop_id, plan_uid, quantity }));
	},
	addOption(id, key, value) {
		dispatch(
			actions.addOption({
				id,
				key,
				value,
			}),
		);
	},
	addPM(id, bank, provider, account_number, account_holder) {
		dispatch(
			actions.addPaymentMethod({
				id,
				bank: bank.id,
				provider,
				account_number,
				account_holder,
			}),
		);
	},
	removeOption(id, key) {
		dispatch(
			actions.removeOption({
				id,
				key,
			}),
		);
	},
	removePM(id, provider) {
		dispatch(
			actions.removePaymentMethod({
				id,
				provider,
			}),
		);
	},
	linkUser(id, phone) {
		dispatch(
			actions.linkUser({
				id,
				phone,
			}),
		);
	},
	unlinkUser(id, shop_id) {
		dispatch(
			actions.unlinkUser({
				id,
				shop_id,
			}),
		);
	},
	resetErrors() {
		dispatch(actions.resetError({ name: constants.FETCH_SHOP_DETAIL }));
	},
	resetDomainError() {
		dispatch(actions.resetError({ name: constants.LINK_DOMAIN }));
	},
	resetUserErrors() {
		dispatch(actions.resetError({ name: constants.LINK_USER }));
		dispatch(actions.resetError({ name: constants.UNLINK_USER }));
	},
	clearForm() {},
	goBackList() {
		dispatch(push('/shops'));
	},
	fetchNotesLatest(merchant_id) {
		dispatch(actions.fetchNotesLatest({ merchant_id }));
	},
	fetchNoteDetail(merchant_id) {
		dispatch(actions.fetchNoteDetail({ merchant_id }));
	},
});

export default (Component) => connect(mapStateToProps, mapDispatchToProps)(Component);
