import { createSelector } from 'reselect';
import * as constants from './constants';

const loadingSelector = function (state, featureName) {
	switch (featureName) {
		case constants.FETCH_NOTES:
			return state.isFetchingNotes;

		case constants.FETCH_NOTE_DETAIL:
			return state.isFetchingNoteDetail;

		case constants.ADD_NOTE:
			return state.isAddingNotes;

		case '__PLACEHOLDER_LOADING__':
			return false;

		default:
			return false;
	}
};

const errorSelector = (state, featureName) => state.errors[featureName] || '';

export const makeErrorSelector = () => {
	return createSelector([errorSelector], (error) => error);
};
export const makeLoadingSelector = () => {
	return createSelector([loadingSelector], (loading) => loading);
};
export const getNotes = createSelector([(state) => state.notes], (notes) => notes);

export const getNoteDetail = createSelector(
	[(state) => state.noteDetail],
	(noteDetail) => noteDetail || {},
);
const __PLACEHOLDER__ = null; // ignore this.

export const getFilter = createSelector([(state) => state.filter], (filter) => filter);
