import core from 'core';
import isEqual from 'lodash/isEqual';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';

function Filter({ filter, clearFilter, handleSubmit, match }) {
	const id = match.params.id;

	return (
		<div className='row mb-2'>
			<div className='col-lg-8'>
				<form className='form-inline d-flex align-items-center' onSubmit={handleSubmit}>
					<div className='form-group mr-2'>
						<Field
							name='start'
							placeholder='Огноо /from/'
							component={core.components.DateTimePicker}
						/>
					</div>
					<div className='form-group mr-2'>
						<Field
							name='end'
							placeholder='Огноо /to/'
							component={core.components.DateTimePicker}
						/>
					</div>
					<div className='form-group'>
						<button type='submit' className='btn btn-primary text-bold'>
							ХАЙХ
						</button>
						{!!(filter && !isEqual(filter, {})) && (
							<button
								onClick={() => clearFilter()}
								className='btn btn-danger ml-2 text-bold'
							>
								<i className='uil-times-square'></i>
							</button>
						)}
					</div>
				</form>
			</div>
			<div className='col-lg-4 text-right'>
				<Link to={`/notes/new/${id}`} className='btn btn-primary mb-2 mr-2'>
					<i className='mdi mdi-plus-circle mr-1' /> Нэмэх
				</Link>
			</div>
		</div>
	);
}

export default withRouter(
	reduxForm({
		form: 'modulesFilterForm',
		enableReinitialize: true,
	})(Filter),
);
