import { push } from 'connected-react-router';
import { connect } from 'react-redux';

import * as actions from '../../../actions';
import * as constants from '../../../constants';
import * as selectors from '../../../selectors';

const error = selectors.makeErrorSelector();
const loading = selectors.makeLoadingSelector();

export const mapStateToProps = (state, { match }) => ({
	addError: error(state[constants.NAME], constants.ADD_NOTE),
	isAdding: loading(state[constants.NAME], constants.ADD_NOTE),
});
export const mapDispatchToProps = (dispatch, { match: { params } }) => ({
	onSubmit(note) {
		if (params.id) {
			dispatch(actions.addNote({ merchant_id: params.id, note }));
		}
	},
	resetErrors() {
		dispatch(actions.resetError({ name: constants.ADD_NOTE }));
	},
	clearForm() {},
	goBackList() {
		dispatch(push(`/notes/${params.id}`));
	},
});

export default (Component) => connect(mapStateToProps, mapDispatchToProps)(Component);
