import { createSelector } from 'reselect';
import * as constants from './constants';

const loadingSelector = function (state, featureName) {
	switch (featureName) {
		case constants.FETCH_SHOPS:
			return state.isFetchingShops;

		case constants.FETCH_SHOP_DETAIL:
			return state.isFetchingShopDetail;

		case constants.FETCH_CATEGORIES:
			return state.isFetchingShopCategories;

		case constants.CHANGE_STATUS:
			return state.isChangingStatus;

		case constants.UPDATE_SHOP:
			return state.isUpdatingShop;

		case constants.ADD_OPTION:
			return state.isAddingOptions;

		case constants.REMOVE_OPTION:
			return state.isRemovingOption;

		case constants.ADD_PAYMENT_METHOD:
			return state.isAddingPM;

		case constants.REMOVE_PAYMENT_METHOD:
			return state.isRemovingPM;

		case constants.LINK_DOMAIN:
			return state.isLinkingDomain;

		case constants.UPDATE_MERCHANT_MODULE:
			return state.isUpdatingBalance;

		case constants.ADJUST_PLAN_MODULE:
			return state.isAdjustingPlanModule;

		case constants.CHECK_DOMAIN:
			return state.isCheckingDomain;

		case constants.REMOVE_DOMAIN:
			return state.isRemovingDomain;

		case constants.FETCH_SHOP_HITS:
			return state.isFetchingShopHits;

		case constants.FETCH_SHOP_ORDER_STAT:
			return state.isFetchingShopOrderStat;

		case constants.ARCHIVE_SHOP:
			return state.isArchivingShop;

		case constants.DOWNLOAD_SHOPS:
			return state.isDownloadingShops;

		case constants.LINK_USER:
			return state.isLinkingUser;

		case constants.UNLINK_USER:
			return state.isUnlinkingUser;

		case constants.FETCH_NOTES_LATEST:
			return state.isFetchingNotesLatest;

		case constants.FETCH_NOTE_DETAIL:
			return state.isFetchingNoteDetail;

		case '__PLACEHOLDER_LOADING__':
			return false;

		default:
			return false;
	}
};

const errorSelector = (state, featureName) => state.errors[featureName] || '';

export const makeErrorSelector = () => {
	return createSelector([errorSelector], (error) => error);
};
export const makeLoadingSelector = () => {
	return createSelector([loadingSelector], (loading) => loading);
};
export const getShops = createSelector([(state) => state.shops], (shops) => shops);
export const getShopDetail = createSelector(
	[(state) => state.shopDetail],
	(shopDetail) => shopDetail,
);
export const getShopCategories = createSelector(
	[(state) => state.shopCategories],
	(categories) => categories,
);
export const getShopHits = createSelector([(state) => state.shopHits], (shopHits) => shopHits);
export const getShopOrderStat = createSelector(
	[(state) => state.shopOrderStat],
	(shopOrderStat) => shopOrderStat,
);
const __PLACEHOLDER__ = null; // ignore this.

export const getFilter = createSelector([(state) => state.filter], (filter) => filter);

export const getNotesLatest = createSelector(
	[(state) => state.notesLatest],
	(notesLatest) => notesLatest,
);

export const getNoteDetail = createSelector(
	[(state) => state.noteDetail],
	(noteDetail) => noteDetail || {},
);
