export const RESET_ERROR = '@@notes/RESET_ERROR';
export const SET_FILTER = '@@notes/SET_FILTER';
export const FETCH_NOTES_BEGIN = '@@notes/FETCH_NOTES_BEGIN';
export const FETCH_NOTES_COMPLETE = '@@notes/FETCH_NOTES_COMPLETE';
export const FETCH_NOTES_ERROR = '@@notes/FETCH_NOTES_ERROR';
export const FETCH_NOTE_DETAIL_BEGIN = '@@notes/FETCH_NOTE_DETAIL_BEGIN';
export const FETCH_NOTE_DETAIL_COMPLETE = '@@notes/FETCH_NOTE_DETAIL_COMPLETE';
export const FETCH_NOTE_DETAIL_ERROR = '@@notes/FETCH_NOTE_DETAIL_ERROR';
export const ADD_NOTE_BEGIN = '@@notes/ADD_NOTE_BEGIN';
export const ADD_NOTE_COMPLETE = '@@notes/ADD_NOTE_COMPLETE';
export const ADD_NOTE_ERROR = '@@notes/ADD_NOTE_ERROR';
const __PLACEHOLDER__ = null; // ignore this.
