import React, { Fragment, useEffect } from 'react';
import { Field } from 'redux-form';

import core from 'core';
import enhancers from './enhancers';

const Loading = core.components.Loading;
const PageTitle = core.components.PageTitle;
const FormGroup = core.components.FormGroup;
const { useFormSubmit } = core.hooks;

const renderTextarea = ({ input, label, meta: { touched, error } }) => (
	<textarea {...input} placeholder={label} rows='3' />
);

export function NoteForm({ handleSubmit, isAdding, addError, resetErrors, goBackList, clearForm }) {
	useEffect(() => () => clearForm(), []);
	const onSubmit = useFormSubmit(isAdding, addError, handleSubmit, resetErrors, goBackList);

	const isSaving = isAdding;

	return (
		<Fragment>
			<PageTitle title={`Тэмдэглэл нэмэх`} />
			<div className='card'>
				<div className='card-body'>
					<form onSubmit={onSubmit}>
						<div className='col px-0 mb-4'>
							<div className='col-xl-6 px-0'>
								<FormGroup required label='Гарчиг'>
									<Field
										required
										name='title'
										type='text'
										component='input'
										disabled={isSaving}
										className='form-control'
										placeholder='Тэмдэглэлийн гарчиг'
									/>
								</FormGroup>
							</div>
							<div className='col-xl-6 px-0'>
								<FormGroup required label='Дэлгэрэнгүй' s>
									<Field
										required
										name='description'
										type='text'
										component='textarea'
										disabled={isSaving}
										className='form-control'
										placeholder='Тэмдэглэлийн дэлгэрэнгүй'
									/>
								</FormGroup>
							</div>
						</div>
						<div className='row'>
							<div className='col-xl-12'>
								<button className='btn btn-primary'>Хадгалах</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</Fragment>
	);
}

const NoteFormWithForm = enhancers.form(NoteForm);
const NoteFormWithState = enhancers.redux(NoteFormWithForm);

export default NoteFormWithState;
