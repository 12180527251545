import * as types from './actionTypes';
export const fetchNotes = ({ merchant_id, filter, options }) => ({
	type: types.FETCH_NOTES_BEGIN,
	payload: {
		merchant_id,
		filter,
		options,
	},
});
export const fetchNoteDetail = ({ id }) => ({
	type: types.FETCH_NOTE_DETAIL_BEGIN,
	payload: {
		id,
	},
});
export const addNote = ({ merchant_id, note }) => ({
	type: types.ADD_NOTE_BEGIN,
	payload: {
		merchant_id,
		note,
	},
});

const __PLACEHOLDER__ = null; // ignore this.

export const resetError = ({ name }) => ({
	type: types.RESET_ERROR,
	payload: {
		name,
	},
});
export const setFilter = ({ filter }) => ({
	type: types.SET_FILTER,
	payload: {
		filter,
	},
});
