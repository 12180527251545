import ServiceFactory from 'lib/serviceFactory';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from './actionTypes';
import * as constants from './constants';

export function* fetchNotes({ payload: { merchant_id, filter, options } }) {
	try {
		const result = yield call(ServiceFactory.call, constants.FETCH_NOTES_URL, {
			merchant_id,
			filter,
			options,
		});
		yield put({
			type: types.FETCH_NOTES_COMPLETE,
			payload: {
				notes: result,
			},
		});
	} catch (error) {
		console.error(error);
		yield put({
			type: types.FETCH_NOTES_ERROR,
			payload: {
				error,
			},
		});
	}
}

export function* fetchNoteDetail({ payload: { id } }) {
	try {
		const result = yield call(ServiceFactory.call, constants.FETCH_NOTE_DETAIL_URL, {
			id,
		});
		yield put({
			type: types.FETCH_NOTE_DETAIL_COMPLETE,
			payload: {
				noteDetail: result,
			},
		});
	} catch (error) {
		yield put({
			type: types.FETCH_NOTE_DETAIL_ERROR,
			payload: {
				error,
			},
		});
	}
}
export function* addNote({ payload: { merchant_id, note } }) {
	try {
		const result = yield call(ServiceFactory.call, constants.ADD_NOTE_URL, {
			merchant_id,
			note,
		});
		yield put({
			type: types.ADD_NOTE_COMPLETE,
		});
	} catch (error) {
		yield put({
			type: types.ADD_NOTE_ERROR,
			payload: {
				error,
			},
		});
	}
}

const __PLACEHOLDER__ = null; // ignore this.

export function* watchSagas() {
	yield takeLatest(types.FETCH_NOTES_BEGIN, fetchNotes);
	yield takeLatest(types.FETCH_NOTE_DETAIL_BEGIN, fetchNoteDetail);
	yield takeLatest(types.ADD_NOTE_BEGIN, addNote);
	const __PLACEHOLDER_WATCHER__ = null;
}
export default [watchSagas()];
