export default class NotesService {
	constructor(fetchUtil, storage) {
		this.storage = storage;
		this.fetchUtil = fetchUtil;
	}

	async fetchNotes({ merchant_id, filter, options }) {
		const { data: notes, total: totalCount } = await this.fetchUtil.sendGet(
			`/merchant-notes/list${this.fetchUtil.qs({
				filter,
				options,
			})}&merchant_id=${merchant_id}`,
		);

		return { notes, totalCount };
	}

	async fetchNoteDetail({ id }) {
		const { data: note } = await this.fetchUtil.sendGet(`/merchant-notes/detail/${id}`);

		return note;
	}

	async addNote({ merchant_id, note }) {
		await this.fetchUtil.sendPost('/merchant-notes/create', {
			merchant_id: merchant_id,
			title: note.title,
			description: note.description,
		});
	}

	__PLACEHOLDER_METHOD__() {}
}
