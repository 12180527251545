export const RESET_ERROR = '@@shops/RESET_ERROR';
export const SET_FILTER = '@@shops/SET_FILTER';
export const FETCH_SHOPS_BEGIN = '@@shops/FETCH_SHOPS_BEGIN';
export const FETCH_SHOPS_COMPLETE = '@@shops/FETCH_SHOPS_COMPLETE';
export const FETCH_SHOPS_ERROR = '@@shops/FETCH_SHOPS_ERROR';
export const FETCH_CATEGORIES_BEGIN = '@@shops/FETCH_CATEGORIES_BEGIN';
export const FETCH_CATEGORIES_COMPLETE = '@@shops/FETCH_CATEGORIES_COMPLETE';
export const FETCH_CATEGORIES_ERROR = '@@shops/FETCH_CATEGORIES_ERROR';
export const FETCH_SHOP_DETAIL_BEGIN = '@@shops/FETCH_SHOP_DETAIL_BEGIN';
export const FETCH_SHOP_DETAIL_COMPLETE = '@@shops/FETCH_SHOP_DETAIL_COMPLETE';
export const FETCH_SHOP_DETAIL_ERROR = '@@shops/FETCH_SHOP_DETAIL_ERROR';
export const CHANGE_STATUS_BEGIN = '@@shops/CHANGE_STATUS_BEGIN';
export const CHANGE_STATUS_COMPLETE = '@@shops/CHANGE_STATUS_COMPLETE';
export const CHANGE_STATUS_ERROR = '@@shops/CHANGE_STATUS_ERROR';
export const UPDATE_SHOP_BEGIN = '@@shops/UPDATE_SHOP_BEGIN';
export const UPDATE_SHOP_COMPLETE = '@@shops/UPDATE_SHOP_COMPLETE';
export const UPDATE_SHOP_ERROR = '@@shops/UPDATE_SHOP_ERROR';
export const ADD_OPTION_BEGIN = '@@shops/ADD_OPTION_BEGIN';
export const ADD_OPTION_COMPLETE = '@@shops/ADD_OPTION_COMPLETE';
export const ADD_OPTION_ERROR = '@@shops/ADD_OPTION_ERROR';
export const REMOVE_OPTION_BEGIN = '@@shops/REMOVE_OPTION_BEGIN';
export const REMOVE_OPTION_COMPLETE = '@@shops/REMOVE_OPTION_COMPLETE';
export const REMOVE_OPTION_ERROR = '@@shops/REMOVE_OPTION_ERROR';
export const ADD_PAYMENT_METHOD_BEGIN = '@@shops/ADD_PAYMENT_METHOD_BEGIN';
export const ADD_PAYMENT_METHOD_COMPLETE = '@@shops/ADD_PAYMENT_METHOD_COMPLETE';
export const ADD_PAYMENT_METHOD_ERROR = '@@shops/ADD_PAYMENT_METHOD_ERROR';
export const REMOVE_PAYMENT_METHOD_BEGIN = '@@shops/REMOVE_PAYMENT_METHOD_BEGIN';
export const REMOVE_PAYMENT_METHOD_COMPLETE = '@@shops/REMOVE_PAYMENT_METHOD_COMPLETE';
export const REMOVE_PAYMENT_METHOD_ERROR = '@@shops/REMOVE_PAYMENT_METHOD_ERROR';
export const UPDATE_MERCHANT_MODULE_BEGIN = '@@shops/UPDATE_MERCHANT_MODULE_BEGIN';
export const UPDATE_MERCHANT_MODULE_COMPLETE = '@@shops/UPDATE_MERCHANT_MODULE_COMPLETE';
export const UPDATE_MERCHANT_MODULE_ERROR = '@@shops/UPDATE_MERCHANT_MODULE_ERROR';
export const ADJUST_PLAN_MODULE_BEGIN = '@@shops/ADJUST_PLAN_MODULE_BEGIN';
export const ADJUST_PLAN_MODULE_COMPLETE = '@@shops/ADJUST_PLAN_MODULE_COMPLETE';
export const ADJUST_PLAN_MODULE_ERROR = '@@shops/ADJUST_PLAN_MODULE_ERROR';
export const LINK_DOMAIN_BEGIN = '@@shops/LINK_DOMAIN_BEGIN';
export const LINK_DOMAIN_COMPLETE = '@@shops/LINK_DOMAIN_COMPLETE';
export const LINK_DOMAIN_ERROR = '@@shops/LINK_DOMAIN_ERROR';
export const CHECK_DOMAIN_BEGIN = '@@shops/CHECK_DOMAIN_BEGIN';
export const CHECK_DOMAIN_COMPLETE = '@@shops/CHECK_DOMAIN_COMPLETE';
export const CHECK_DOMAIN_ERROR = '@@shops/CHECK_DOMAIN_ERROR';
export const REMOVE_DOMAIN_BEGIN = '@@shops/REMOVE_DOMAIN_BEGIN';
export const REMOVE_DOMAIN_COMPLETE = '@@shops/REMOVE_DOMAIN_COMPLETE';
export const REMOVE_DOMAIN_ERROR = '@@shops/REMOVE_DOMAIN_ERROR';
export const FETCH_SHOP_HITS_BEGIN = '@@shops/FETCH_SHOP_HITS_BEGIN';
export const FETCH_SHOP_HITS_COMPLETE = '@@shops/FETCH_SHOP_HITS_COMPLETE';
export const FETCH_SHOP_HITS_ERROR = '@@shops/FETCH_SHOP_HITS_ERROR';
export const FETCH_SHOP_ORDER_STAT_BEGIN = '@@shops/FETCH_SHOP_ORDER_STAT_BEGIN';
export const FETCH_SHOP_ORDER_STAT_COMPLETE = '@@shops/FETCH_SHOP_ORDER_STAT_COMPLETE';
export const FETCH_SHOP_ORDER_STAT_ERROR = '@@shops/FETCH_SHOP_ORDER_STAT_ERROR';
export const ARCHIVE_SHOP_BEGIN = '@@shops/ARCHIVE_SHOP_BEGIN';
export const ARCHIVE_SHOP_COMPLETE = '@@shops/ARCHIVE_SHOP_COMPLETE';
export const ARCHIVE_SHOP_ERROR = '@@shops/ARCHIVE_SHOP_ERROR';
export const DOWNLOAD_SHOPS_BEGIN = '@@shops/DOWNLOAD_SHOPS_BEGIN';
export const DOWNLOAD_SHOPS_COMPLETE = '@@shops/DOWNLOAD_SHOPS_COMPLETE';
export const DOWNLOAD_SHOPS_ERROR = '@@shops/DOWNLOAD_SHOPS_ERROR';
export const LINK_USER_BEGIN = '@@shops/LINK_USER_BEGIN';
export const LINK_USER_COMPLETE = '@@shops/LINK_USER_COMPLETE';
export const LINK_USER_ERROR = '@@shops/LINK_USER_ERROR';
export const UNLINK_USER_BEGIN = '@@shops/UNLINK_USER_BEGIN';
export const UNLINK_USER_COMPLETE = '@@shops/UNLINK_USER_COMPLETE';
export const UNLINK_USER_ERROR = '@@shops/UNLINK_USER_ERROR';
export const FETCH_NOTES_LATEST_BEGIN = '@@shops/FETCH_NOTES_LATEST_BEGIN';
export const FETCH_NOTES_LATEST_COMPLETE = '@@shops/FETCH_NOTES_LATEST_COMPLETE';
export const FETCH_NOTES_LATEST_ERROR = '@@shops/FETCH_NOTES_LATEST_ERROR';
export const FETCH_NOTE_DETAIL_BEGIN = '@@notes/FETCH_NOTE_DETAIL_BEGIN';
export const FETCH_NOTE_DETAIL_COMPLETE = '@@notes/FETCH_NOTE_DETAIL_COMPLETE';
export const FETCH_NOTE_DETAIL_ERROR = '@@notes/FETCH_NOTE_DETAIL_ERROR';
const __PLACEHOLDER__ = null; // ignore this.
