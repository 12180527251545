import core from 'core';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
const Loading = core.components.Loading;

function Notes({
	shop,
	isFetchingNotes,
	fetchNotesResult = [],
	fetchNotesLatest,
	isFetchingNoteDetail,
	fetchNoteDetailResult,
	fetchNoteDetail,
}) {
	useEffect(() => {
		fetchNotesLatest(shop.id);
	}, []);

	function formatDate(date) {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;

		return [year, month, day].join('-');
	}

	return (
		<div className='card'>
			<div className='card-body'>
				<div className='d-flex justify-content-between align-items-center mb-2'>
					<div className='card-title mb-0'>Тэмдэглэл</div>
					<Link to={`/notes/new/${shop.id}`} className='btn btn-primary text-bold'>
						<i className='uil-plus' />
					</Link>
				</div>

				{isFetchingNotes === undefined || isFetchingNotes === true ? (
					<Loading />
				) : (
					<ul className='list-group list-group-flush notes-list'>
						{fetchNotesResult?.notesLatest &&
							fetchNotesResult?.notesLatest.map((note, i) => (
								<>
									<li
										key={i + 1}
										className='list-group-item px-0 d-flex justify-content-between align-items-center'
									>
										<span>{i + 1}</span>
										<span className='text-truncate w-50'>{note.title}</span>
										<span>{formatDate(note.created_at)}</span>
										<span className='text-truncate'>{note.username}</span>
										<button
											type='button'
											className='btn btn-link'
											data-toggle='modal'
											data-target='#noteModal'
										>
											<i className='mdi mdi-arrow-right font-md fs-2' />
										</button>
									</li>
									<div
										className='modal fade'
										id='noteModal'
										tabindex='-1'
										role='dialog'
										aria-labelledby='noteModal'
										aria-hidden='true'
									>
										<div className='modal-dialog' role='document'>
											<div className='modal-content'>
												<div className='modal-header'>
													<button
														type='button'
														className='close'
														data-dismiss='modal'
														aria-label='Close'
													>
														<span aria-hidden='true'>
															<i className='uil uil-multiply' />
														</span>
													</button>
												</div>
												<div className='modal-body'>
													<div className='d-flex align-item-center justify-content-between mb-2'>
														<p class='card-text'>{note.username}</p>
														<p class='card-text'>{formatDate(note.created_at)}</p>
													</div>
													<h5 class='card-title'>{note.title}</h5>
													<p class='card-text'>{note.description}</p>
												</div>
											</div>
										</div>
									</div>
								</>
							))}
					</ul>
				)}
			</div>
			<div className='card-footer text-muted d-flex justify-content-end'>
				<Link to={`/notes/${shop.id}`} className='btn btn-primary text-bold'>
					Дэлгэрэнгүй
				</Link>
			</div>
		</div>
	);
}

export default Notes;
