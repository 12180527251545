import screens from './screens';

export default [
	{
		exact: true,
		path: '/notes/:id',
		component: screens.NotesList,
	},
	{
		exact: true,
		path: '/notes/new/:id',
		component: screens.NotesForm,
	},
];
