import React from 'react';

import { Field, reduxForm } from 'redux-form';

import core from 'core';

function PlanAdjustionTable({ plan, isAdjustingPlanModule, handleSubmit }) {
	return (
		<div className='row mb-2'>
			<div className='col-lg-12'>
				<form onSubmit={handleSubmit} className='form-inline shop-filter-form'>
					<div style={{ width: 140 }} className='form-group mr-2'>
						<Field
							required
							name='plan_uid'
							disabled={isAdjustingPlanModule}
							placeholder='Багц сонгох'
							options={[
								{ label: 'STARTER Багц', value: 'starter' },
								{ label: 'BASIC Багц', value: 'basic' },
								{ label: 'STANDART Багц', value: 'standart' },
								{ label: 'ADVANCED Багц', value: 'advanced' },
							]}
							component={core.components.SelectField}
						/>
					</div>
					<div style={{ width: 140 }} className='form-group mr-2'>
						<Field
							name='quantity'
							disabled={isAdjustingPlanModule}
							className='form-control'
							placeholder='Дуусах хугацаа'
							dateFormat='yyyy-MM-dd'
							component={core.components.DateTimePicker}
						/>
						{/* <Field
              style={{ width: 140 }}
              required
              disabled={isAdjustingPlanModule}
              component="input"
              type="number"
              className="form-control"
              placeholder="Хугацаа /Сараар/"
              name="quantity"
            /> */}
					</div>
					<div className='form-group'>
						<button disabled={isAdjustingPlanModule} className='btn btn-primary text-bold'>
							ХАДГАЛАХ
						</button>
					</div>
				</form>
			</div>
		</div>
	);
}

export default reduxForm({
	form: 'adjustPlanTable',
	enableReinitialize: true,
})(PlanAdjustionTable);
