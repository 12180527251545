import core from 'core';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import React, { Fragment, useEffect } from 'react';
import enhancers from './enhancers';
import Filter from './partials/filter';
import Table from './partials/table';
const Loading = core.components.Loading;
const PageTitle = core.components.PageTitle;
const Pagination = core.components.Pagination;

export function NoteList({
	isFetching,
	filter,
	setFilter,
	clearFilter,
	currentPage,
	fetchResult,
	fetchNotes,
	currentUser,
	limit,
	pages,
	updateQueryString,
}) {
	useEffect(() => {
		fetchNotes(currentPage, filter);
	}, [currentPage, filter]);

	return (
		<Fragment>
			<PageTitle title='Тэмдэглэл' />
			<div className='card'>
				{/* <Link to={`/notes/new/${id}`} className='btn btn-primary text-bold'>
					<i className='uil-plus' />
				</Link> */}
				<div className='card-body'>
					<Filter
						filter={filter}
						setFilter={setFilter}
						clearFilter={clearFilter}
						onSubmit={(formData) => {
							if (!isEqual(formData, {})) {
								updateQueryString(1);
								const theFilter = pick(formData, ['name', 'phone']);

								if (formData.start) {
									theFilter.start = formData.start.toISOString();
								}

								if (formData.end) {
									theFilter.end = formData.end.toISOString();
								}

								setFilter(theFilter);
							}
						}}
					/>

					{isFetching === undefined || isFetching === true ? (
						<Loading />
					) : (
						<>
							<Table currentPage={currentPage} limit={limit} rows={fetchResult.notes} />
							<Pagination url='/notes' pages={pages} currentPage={currentPage} />
						</>
					)}
				</div>
			</div>
		</Fragment>
	);
}
const NoteListWithState = enhancers.redux(NoteList);
export default NoteListWithState;
