export const NAME = 'shops';
export const FETCH_SHOPS = 'fetch_shops';
export const FETCH_SHOPS_URL = 'shops/fetchShops';
export const FETCH_SHOP_DETAIL = 'fetch_shop_detail';
export const FETCH_SHOP_DETAIL_URL = 'shops/fetchShopDetail';
export const FETCH_CATEGORIES = 'fetch_shop_detail';
export const FETCH_CATEGORIES_URL = 'shops/fetchCategories';
export const CHANGE_STATUS = 'change_status';
export const CHANGE_STATUS_URL = 'shops/changeStatus';
export const UPDATE_SHOP = 'update_shop';
export const UPDATE_SHOP_URL = 'shops/updateShop';
export const UPDATE_MERCHANT_MODULE = 'update_merchant_balance';
export const UPDATE_MERCHANT_MODULE_URL = 'shops/updateBalance';
export const ADJUST_PLAN_MODULE = 'adjust_plan_module';
export const ADJUST_PLAN_MODULE_URL = 'shops/adjustPlanModule';
export const ADD_OPTION = 'add_option';
export const ADD_OPTION_URL = 'shops/addOption';
export const REMOVE_OPTION = 'remove_option';
export const REMOVE_OPTION_URL = 'shops/removeOption';
export const ADD_PAYMENT_METHOD = 'add_payment_method';
export const ADD_PAYMENT_METHOD_URL = 'shops/addPaymentMethod';
export const REMOVE_PAYMENT_METHOD = 'remove_payment_method';
export const REMOVE_PAYMENT_METHOD_URL = 'shops/removePaymentMethod';
export const LINK_DOMAIN = 'link_domain';
export const LINK_DOMAIN_URL = 'shops/linkDomain';
export const CHECK_DOMAIN = 'check_domain';
export const CHECK_DOMAIN_URL = 'shops/checkDomain';
export const REMOVE_DOMAIN = 'remove_domain';
export const REMOVE_DOMAIN_URL = 'shops/removeDomain';
export const FETCH_SHOP_HITS = 'fetch_shop_hits';
export const FETCH_SHOP_HITS_URL = 'shops/fetchShopHits';
export const FETCH_SHOP_ORDER_STAT = 'fetch_shop_order_stat';
export const FETCH_SHOP_ORDER_STAT_URL = 'shops/fetchShopOrderStat';
export const ARCHIVE_SHOP = 'archive_shop';
export const ARCHIVE_SHOP_URL = 'shops/archiveShop';
export const DOWNLOAD_SHOPS = 'download_shops';
export const DOWNLOAD_SHOPS_URL = 'shops/downloadShops';
export const LINK_USER = 'link_user';
export const LINK_USER_URL = 'shops/linkUser';
export const UNLINK_USER = 'unlink_user';
export const UNLINK_USER_URL = 'shops/unlinkUser';
export const FETCH_NOTES_LATEST = 'fetch_notes_latest';
export const FETCH_NOTES_LATEST_URL = 'shops/fetchNotesLatest';
export const FETCH_NOTE_DETAIL = 'fetch_note_detail';
export const FETCH_NOTE_DETAIL_URL = 'shops/fetchNoteDetail';
const __PLACEHOLDER__ = null; // ignore this.
