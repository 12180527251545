/* eslint-disable react-hooks/exhaustive-deps */
import pick from "lodash/pick";
import isEqual from "lodash/isEqual";
import React, { Fragment, useEffect, useState } from "react";

import core from "core";
import enhancers from "./enhancers";

import Table from "./partials/table";
import Filter from "./partials/filter";

const Loading = core.components.Loading;
const PageTitle = core.components.PageTitle;
const Pagination = core.components.Pagination;

export function ShopList({
  isFetching,
  isArchivingShop,
  isChangingStatus,
  filter,
  setFilter,
  clearFilter,
  currentPage,
  fetchResult,
  list,
  archive,
  pages,
  limit,
  shopType,
  currentUser,
  changeStatus,
  updateQueryString,
  isDownloadingShops,
  downloadShops,
}) {
  const [status, setStatus] = useState(shopType);

  useEffect(
    () => list(currentPage, { ...filter, status }),
    [currentPage, filter, status]
  );

  useEffect(() => {
    if (!isChangingStatus) {
      list(currentPage, { ...filter, status });
    }
  }, [isChangingStatus]);

  useEffect(() => {
    if (!isArchivingShop) {
      list(currentPage, { ...filter, status });
    }
  }, [isArchivingShop]);

  const isLoading = isChangingStatus || isFetching;
  return (
    <Fragment>
      <PageTitle title="Бүх дэлгүүрүүд" />
      <div className="card">
        <div className="card-body">
          <Filter
            newUrl="/shops/new"
            filter={filter}
            status={status}
            currentUser={currentUser}
            setStatus={setStatus}
            setFilter={setFilter}
            clearFilter={clearFilter}
            downloadShops={downloadShops}
            isDownloadingShops={isDownloadingShops}
            updateShopType={(status) => updateQueryString(currentPage, status)}
            onSubmit={(formData) => {
              if (!isEqual(formData, {})) {
                updateQueryString(1, status);
                const theFilter = pick(formData, ["name", "phone", "email"]);

                if (formData.product_range) {
                  theFilter.product_range = formData.product_range.id;
                }

                if (formData.order_range) {
                  theFilter.order_range = formData.order_range.id;
                }

                if (formData.start) {
                  theFilter.start = formData.start.toISOString();
                }

                if (formData.end) {
                  theFilter.end = formData.end.toISOString();
                }

                if (formData.expire_start) {
                  theFilter.expire_start = formData.expire_start.toISOString();
                }

                if (formData.expire_end) {
                  theFilter.expire_end = formData.expire_end.toISOString();
                }

                if (
                  Array.isArray(formData.channels) &&
                  formData.channels.length
                ) {
                  theFilter.channels = `"${formData.channels
                    .map((ch) => ch.id)
                    .join('","')}"`;
                }

                if (
                  Array.isArray(formData.category_codes) &&
                  formData.category_codes.length
                ) {
                  theFilter.category_codes = `"${formData.category_codes
                    .map((ch) => ch.id)
                    .join('","')}"`;
                }

                setFilter(theFilter);
              }
            }}
          />

          {isLoading === undefined || isLoading === true ? (
            <Loading />
          ) : (
            <Fragment>
              <Table
                limit={limit}
                status={status}
                archive={archive}
                rows={fetchResult.shops}
                currentPage={currentPage}
                changeStatus={changeStatus}
              />
              <Pagination
                url="/shops"
                suffix={`&shopType=${status}`}
                pages={pages}
                currentPage={currentPage}
              />
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
}

const ShopListWithState = enhancers.redux(ShopList);
export default ShopListWithState;
