import * as types from './actionTypes';
export const fetchShops = ({ filter, options }) => ({
	type: types.FETCH_SHOPS_BEGIN,
	payload: {
		filter,
		options,
	},
});
export const fetchShopDetail = ({ id }) => ({
	type: types.FETCH_SHOP_DETAIL_BEGIN,
	payload: {
		id,
	},
});
export const fetchCategories = () => ({
	type: types.FETCH_CATEGORIES_BEGIN,
});
export const changeStatus = ({ id, status }) => ({
	type: types.CHANGE_STATUS_BEGIN,
	payload: {
		id,
		status,
	},
});
export const updateShop = ({ id, shop }) => ({
	type: types.UPDATE_SHOP_BEGIN,
	payload: {
		id,
		shop,
	},
});
export const addOption = ({ id, key, value }) => ({
	type: types.ADD_OPTION_BEGIN,
	payload: {
		id,
		key,
		value,
	},
});
export const removeOption = ({ id, key }) => ({
	type: types.REMOVE_OPTION_BEGIN,
	payload: {
		id,
		key,
	},
});
export const addPaymentMethod = ({ id, bank, provider, account_number, account_holder }) => ({
	type: types.ADD_PAYMENT_METHOD_BEGIN,
	payload: {
		id,
		bank,
		provider,
		account_number,
		account_holder,
	},
});
export const removePaymentMethod = ({ id, provider }) => ({
	type: types.REMOVE_PAYMENT_METHOD_BEGIN,
	payload: {
		id,
		provider,
	},
});
export const linkDomain = ({ id, domain, theme }) => ({
	type: types.LINK_DOMAIN_BEGIN,
	payload: {
		id,
		domain,
		theme,
	},
});
export const updateBalance = ({ shop_id, balance }) => ({
	type: types.UPDATE_MERCHANT_MODULE_BEGIN,
	payload: {
		shop_id,
		balance,
	},
});
export const adjustPlanModule = ({ shop_id, plan_uid, quantity }) => ({
	type: types.ADJUST_PLAN_MODULE_BEGIN,
	payload: {
		shop_id,
		plan_uid,
		quantity,
	},
});
export const checkDomain = ({ id, domain }) => ({
	type: types.CHECK_DOMAIN_BEGIN,
	payload: {
		id,
		domain,
	},
});
export const removeDomain = ({ id }) => ({
	type: types.REMOVE_DOMAIN_BEGIN,
	payload: {
		id,
	},
});
export const fetchShopHits = ({ shop_id }) => ({
	type: types.FETCH_SHOP_HITS_BEGIN,
	payload: {
		shop_id,
	},
});
export const fetchShopOrderStat = ({ shop_id }) => ({
	type: types.FETCH_SHOP_ORDER_STAT_BEGIN,
	payload: {
		shop_id,
	},
});
export const archiveShop = ({ id }) => ({
	type: types.ARCHIVE_SHOP_BEGIN,
	payload: {
		id,
	},
});
export const downloadShops = ({ filter }) => ({
	type: types.DOWNLOAD_SHOPS_BEGIN,
	payload: {
		filter,
	},
});
export const linkUser = ({ id, phone }) => ({
	type: types.LINK_USER_BEGIN,
	payload: {
		id,
		phone,
	},
});
export const unlinkUser = ({ id, shop_id }) => ({
	type: types.UNLINK_USER_BEGIN,
	payload: {
		id,
		shop_id,
	},
});
const __PLACEHOLDER__ = null; // ignore this.

export const resetError = ({ name }) => ({
	type: types.RESET_ERROR,
	payload: {
		name,
	},
});
export const setFilter = ({ filter }) => ({
	type: types.SET_FILTER,
	payload: {
		filter,
	},
});
export const fetchNotesLatest = ({ merchant_id }) => ({
	type: types.FETCH_NOTES_LATEST_BEGIN,
	payload: {
		merchant_id,
	},
});
export const fetchNoteDetail = ({ merchant_id }) => ({
	type: types.FETCH_NOTE_DETAIL_BEGIN,
	payload: {
		merchant_id,
	},
});
